import React from "react";
import "./TermConditionNew.css";
function TermConditionNew() {
  return (
    <div className="TANDCwrapper">
      <h1 className="termHead">DriveU Terms and Conditions</h1>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Welcome to our mobile application and / or our website.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              document is published in accordance with the provisions of Rule 3
              (1) of the Information Technology (Intermediaries guidelines)
              Rules, 2011 that require publishing the rules and regulations,
              privacy policy and Terms of Use for access or usage of
              www.driveu.in website and DriveU applications for mobile and
              handheld devices.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              These terms and conditions of use (‘’
              <span style={{ fontWeight: "800" }}>Terms of Use</span>’’) of the
              DriveU website and mobile app, is a legal agreement("
              <span style={{ fontWeight: "800" }}>Agreement</span>") between
              Humble Mobile Solutions Pvt. Ltd. (“
              <span style={{ fontWeight: "800" }}>Humble Mobile</span>”), a
              company incorporated under the Companies Act, 2013 (India) having
              its offices at 112/2 KHB Colony, 17th Main, 5th Block,
              Koramangala, Bengaluru – 560095, Karnataka and
              yourself/yourselves. If you continue to browse the website and/or
              use the mobile application you are agreeing to comply with and be
              bound by the Terms of Use.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              If you do not agree with the Terms of Use, please do not access
              and use this Website / Application (as hereinafter defined) or our
              Service through all other means.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">1. DEFINITIONS:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              “DriveU”, “we”, “our” and “us” shall mean Humble Mobile;
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              “You”, yourself / yourselves and “your” shall mean a User, who
              meets the eligibility criteria set out below;
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              “Application” shall mean the application supplied by DriveU and
              downloaded and installed by you on your single mobile device
              (smart phone);
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              “Driver” or ‘’Drivers’’ shall mean the driver engaged by you by
              using our Website/Application;
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              “Force Majeure” shall have the meaning as set out in Clause VII;
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              “Service” or “Services” shall mean the service of providing
              short-term private Driver solutions via the Application/Website as
              defined in Clause I;
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              ‘’User’’ or ‘’Users’’ shall have the meaning as set out in Clause
              III; and
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              ‘’Website’’ shall mean the website www.driveu.in.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              These Terms of Use may be modified from time to time in our sole
              discretion. It is your responsibility to review these Terms and
              Conditions from time to time. If you continue to use the Service
              after notice of change has been intimated or published on our
              Website/Application, you thereby provide your consent to the
              changed practices on the same terms hereof. For this reason, we
              encourage you to review these Terms of Use each time you access
              and use the Website/Application. Most content and some of the
              features on the Website/Application are made available to Users
              free of charge. However, DriveU reserves the right to terminate
              access to certain areas or features of the Website/Application at
              any time for any reason, with or without notice.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              If you are not agreeable to these Terms of Use, you may not use
              the Services, the Website or the Application.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2. SERVICES DESCRIPTION:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              1. Humble Mobile Solutions Private Limited operates a technology
              based electronic platform service called ‘DriveU’ which seeks to
              provide drivers-on-demand to customers who wish to obtain the
              services of a driver.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              2. DriveU enables the hiring of drivers by commuters/ passengers
              for a point to point pick up and drop off Service, within city
              limits and outside city limits, through the Application in mobile
              telecommunications devices and through the Website. The Service is
              designed to offer you information and a means of obtaining
              short-term private driver solutions to coordinate point-to-point
              and round-trip private driver services, at user’s request. As a
              User, you authorize the opted Driver to operate your vehicle and
              make decisions on your behalf during a period of time designated
              by you.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              3. The Drivers offering their services on the DriveU platform are
              individuals and independent service providers who have opted to
              enlist themselves with DriveU. The drivers are screened and
              trained prior to being listed as a driver on the DriveU platform.
              At no point can the driver represent himself to be an employee of
              Humble Mobile Solutions Private Limited/DriveU. The user terms and
              conditions in here expressly provide that the drivers are
              independent service providers and are not employees of Humble
              Mobile Solutions Private Limited/DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              4. Further, it is to be noted that DriveU, in itself does not
              provide driving / transportation services. The customer offers the
              mode of transportation. The terms and conditions clearly state
              that the provision of services by the driver to the customer is,
              subject to the agreement entered into between them and DriveU
              shall under no circumstance be a party to such agreement.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              5. To elaborate, the modus operandi is as follows:
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "none" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#444545" }}>
                a. ‘DriveU’ aims at bringing drivers/operators (service
                providers) and customers owning private cars on a common
                platform by which the said customers can place a request for the
                services of a professional driver for commute.
              </span>
            </span>
          </span>
          <br />
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#444545" }}>
                b. The e-commerce platform brings the customer into contact with
                a driver so as to avail the latter’s services, subject to the
                availability of drivers in or around the customer’s location at
                the time of registering his request.
              </span>
            </span>
          </span>
          <br />
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#444545" }}>
                c. The request by the customer could be for a point-to-point
                commute or a round trip is placed by the customer electronically
                over a mobile based app or through an online website operated by
                DriveU or via phone service.
              </span>
            </span>
          </span>
          <br />
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#444545" }}>
                d. Towards the end of the commute, the customers are charged for
                the services of the driver, which is intimated to them over the
                app or mail. The services may be paid for by the customers
                either:
              </span>
            </span>
          </span>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11pt" }}>
                <span style={{ fontFamily: "Circular-Std-Book" }}>
                  <span style={{ color: "#444545" }}>
                    in cash, directly to the driver or
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11pt" }}>
                <span style={{ fontFamily: "Circular-Std-Book" }}>
                  <span style={{ color: "#444545" }}>
                    through online payment options, to DriveU
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              e. Where the customer opts to pay for the drivers’ services
              through any of the online modes available on the app, DriveU
              remits the proceeds collected from the customers to the respective
              drivers.
            </span>
          </span>
        </span>
        <br />
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              f. In return for the use of the DriveU’s electronic platform, the
              Drivers are charged a convenience fee by DriveU. DriveU remits any
              applicable tax to the credit of the respective Governments and
              regularly files periodic returns as prescribed under the GST laws.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              3. USE OF SERVICE, APPLICATION AND WEBSITE:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The Application and the Website allows you to send a request for
              Service to a Driver. The GPS receiver, which should be installed
              on the mobile device (smart phone) on which you have downloaded
              the Application or the Website, shall detect your location and
              shall send your location information to the relevant Driver.
              DriveU has sole and complete discretion to accept or reject each
              request for providing the Service. DriveU also has sole and
              complete discretion over whether to use the Application / Website
              to receive the leads generated through the Application / Website.
              If DriveU accepts a request, the Application / Website notifies
              you and provides information regarding the Driver - including his
              name and the ability to contact the Driver by telephone or a
              message. The Application / Website also allow you to view the
              Driver’s progress towards the pick-up point, in real time.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall undertake commercially reasonable efforts to bring
              you into contact with a Driver in order to avail the Services,
              subject to the availability of Drivers in or around your location
              at the moment of your request for the Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU itself does not provide transportation services. It is up
              to the User to offer transportation. DriveU only acts as
              intermediary between the Driver and you. The provision of the
              Services by the Driver to you is, therefore, subject to the
              agreement (to be) entered into between the Driver and you. DriveU
              shall under no circumstance be a party to such agreement. DriveU
              disclaims any and all liability in respect of the Drivers
              including any claims of employment or any vicarious liability
              arising out of the Service or otherwise.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">4. USER ELIGIBILITY AND AGREEMENT:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              User means any individual or business entity/organization that
              legally operates in India or in other countries, and uses and has
              the right to use the Services provided by DriveU. Our Services are
              available only to those individuals or entities who can execute
              legally binding contracts under the applicable law. Therefore, a
              User must not be a minor as per Indian Law; i.e. User(s) must be
              at least 18 years of age to be eligible to use our Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU advises its Users that while accessing the
              Website/Application, they must follow/abide by the applicable
              laws. DriveU may, in its sole discretion, refuse the Service to
              anyone at any time.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              This Agreement applies to all Services offered on the
              Website/Application, collectively with any additional terms and
              condition that may be applicable.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">5. REGISTRATION:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              To use the Services, you have to be registered and provide your
              name, contact number, email address and other details. Please see
              our Privacy Policy and practices to know more about how your
              personal information would be used.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">6. REPRESENTATIONS AND WARRANTIES:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Medium" }}>
            <span style={{ color: "#444545" }}>
              As a precondition to your use of the Services, you represent and
              warrant that:
            </span>
          </span>
        </span>
      </p>
      {/* <p>&nbsp;</p> */}
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The information you provide to DriveU is accurate and complete.
              DriveU service is only available for private cars for
              non-commercial purposes within the city limits as designated on
              our Website. Private cars for non-commercial purposes bear license
              plates with black lettering over white colored background
              (Commercial vehicles have license plates with black lettering over
              yellow colored background). You will ensure that DriveU service is
              being utilized only for non-commercial purposes in a private car.
              DriveU is entitled, at all times, to verify the information that
              you have provided and to refuse the Service or use of the
              Application / Website without providing reasons.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will only access the Service using authorized means. You are
              responsible to check and ensure you download the correct
              Application for your device or the correct Website in your
              computer. DriveU shall not be liable if you do not have a
              compatible mobile device or if you download the wrong version of
              the Application for your mobile device or Website for the
              computer. DriveU reserves the right to terminate the Service and
              the use of the Application/ Website should you use the Service or
              Application with an incompatible or unauthorized device.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You have the legal right and authority to possess and operate the
              vehicle when engaging our Services and you confirm, represent and
              warrant that the said vehicle is in good operating condition and
              meets the industry safety standards and all applicable statutory
              requirements for a motor vehicle of its kind.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will be solely responsible for any and all liability which
              results from or is alleged as a result of the condition of your
              vehicle, legal compliance, etc., including, but not limited to,
              personal injuries, death and property damages.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will be solely responsible for the full functionality of your
              vehicle. If your vehicle fails to function (electrical, mechanical
              or other) in any way while the Services are being availed of by
              you, you will be responsible for all storage fees, roadside
              assistance, alternate transportation and repair of any kind and
              neither Humble Mobile/DriveU nor the Driver shall be responsible
              in any manner whatsoever. You have the legal right to designate
              the Driver as your agent and delegate actual authority to the
              Driver to operate your vehicle and make decisions on your behalf
              for the purposes of providing Services offered through the DriveU
              platform.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You are named or scheduled on the insurance policy covering the
              vehicle you use when engaging our Services. You have a valid
              policy of liability insurance (in coverage amounts consistent with
              all applicable legal requirements) for the operation of your
              vehicle to cover any anticipated losses related to your
              participation in the Services or the operation of your vehicle by
              the Driver. In the event of a motor vehicle accident you will be
              solely responsible for compliance with any applicable statutory or
              department of motor vehicles requirements and for all necessary
              contacts with your insurance provider. Other than any personal
              criminal liability attaching to the Driver you will be solely
              responsible for all consequences arising out of the use of the
              Service or the Driver. In any event DriveU shall have no
              responsibility or liability on this account whatsoever.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You specifically authorize us to use, store or otherwise process
              your 'Sensitive personal data or information’ (as such term is
              defined in Information Technology (Reasonable security practices
              and procedures and sensitive personal data or information) Rules,
              2011) in order to provide the Services to you. Subject to
              applicable law all information provided to us by you shall be
              deemed to be our information to use as we desire.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will obey all applicable laws related to the matters set forth
              herein and will be solely responsible for any violations of the
              same.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              7. METER START AND CANCELLATION POLICY:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU drivers will wait no more than 15 minutes from the
              requested trip start time, before they start the trip timer.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              You can cancel a driver service booking any time through the
              DriverU App or Website but may be charged a cancellation fee if
              you cancel after the driver is already on the way.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">8. PAYMENT</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              When a trip ends, DriveU driver ends the trip on the DriveU
              Partner app on his mobile device. It then calculates the total
              fare amount due from You and it appears on the DriveU app on your
              mobile device. You may pay the amount via cash or a variety of
              online payment options available on the app including payment via
              the DriveU Wallet where You may have already pre-loaded funds. The
              usage of the DriveU Wallet shall be subject to the terms and
              conditions of the third party payment processor providing the
              mobile payment service on the App. Any payment related issue,
              except when such issue is due to an error or fault in the Site or
              Application, shall be resolved between You and the payment
              processor. DriveU shall not be responsible for any unauthorized
              use of Your DriveU Wallet.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">9. LIMITATION OF LIABILITY:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The information, recommendations and/or Services provided to you
              on or through the Website/Application are for general information
              purposes only and do not constitute advice. DriveU will take
              reasonable steps to keep the Website/Application and its contents
              correct and up to date but does not guarantee that the contents of
              the Website/Application are free of errors, defects, malware and
              viruses or that the Website/Application are correct, up to date
              and accurate.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU shall not be liable for any damages resulting from the use
              of, or inability to use, the Website/Application, including
              damages caused by malware, viruses or any incorrectness or
              incompleteness of the information on the Website/Application.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU shall further not be liable for damages resulting from the
              use of, or the inability to use, electronic means of communication
              with the Website/Application, including — but not limited to —
              damages resulting from failure or delay in delivery of electronic
              communications, interception or manipulation of electronic
              communications by third parties or by computer programs used for
              electronic communications and transmission of viruses.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Without prejudice to the foregoing, and insofar as allowed under
              mandatory applicable law, DriveU’s aggregate liability shall in no
              event exceed the equivalent of the amount for the payment of the
              Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The quality of the Services requested through the use of the
              Application is entirely the responsibility of the Driver who
              ultimately provides such transportation services to you. DriveU
              under no circumstance accepts liability in connection with and/or
              arising from the Services provided by the Driver or any acts,
              actions, behaviour, conduct, and/or negligence on the part of the
              Driver.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We shall not be held liable for any failure or delay in performing
              Services where such failure arises as a result of any act or
              omission, which is outside our reasonable control such as
              unprecedented circumstances, overwhelming and unpreventable events
              caused directly and exclusively by forces of nature that can be
              neither anticipated, nor controlled, nor prevented by the exercise
              of prudence, diligence, and care, including but not limited to:
              war, riot, civil commotion; compliance with any law or
              governmental order, rule, regulation or direction and acts of
              third parties ( “Force Majeure” ).
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              If we have contracted to provide identical or similar Service to
              more than one User and are prevented from fully meeting our
              obligations to you by reason of an event of Force Majeure, we may
              decide at our absolute discretion which booking we will fulfill by
              providing the Service, and to what extent.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We have taken all reasonable steps to prevent internet fraud and
              ensure any data collected from you is stored as securely and
              safely as possible. However, we shall not be held liable in the
              unlikely event of a breach in our secure computer servers or those
              of third parties other than as required under applicable law.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              In the event we have a reasonable belief that there exists an
              abuse of vouchers and/or discount codes or suspect an instance of
              fraud, we may cause the User to be blocked immediately and reserve
              the right to refuse future Service. Additionally, should there
              exist an abuse of vouchers or discount codes, DriveU reserves the
              right to seek compensation from any and all such Users.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU does not represent or endorse the accuracy or reliability
              of any information, or advertisements (collectively, the
              "Content") contained on, distributed through, or linked,
              downloaded or accessed from or contained on the
              Website/Application, or the quality of any products, information
              or other materials displayed, or obtained by you as a result of an
              advertisement or any other information or offer in or in
              connection with the Service.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Offers are subject to DriveU's discretion and may be withdrawn at
              any time and without notice.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">10. INTELLECTUAL PROPERTY RIGHTS:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU is the sole owner or lawful licensee of all the rights to
              the Website/Application and its content. Website/Application
              content means its design, layout, text, images, graphics, sound,
              video etc. The Website/Application content embodies trade secrets
              and intellectual property rights protected under worldwide
              copyright and other laws. All title, ownership and intellectual
              property rights in the Website/Application and its content shall
              remain with DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              All rights, not otherwise claimed under this Agreement or in the
              Website /Application, are hereby reserved. The information
              contained in this Website/Application is intended, solely to
              provide general information for the personal use of the reader,
              who accepts full responsibility for its use.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              You may access the Website/Application, avail of the features,
              facilities and Services for your personal or internal requirements
              only. You are not entitled to duplicate, distribute, create
              derivative works of, display, or commercially exploit the
              Website/Application Content, features or facilities, directly or
              indirectly, without our prior written permission of DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>Copyright</span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              All content on this Website/Application is the copyright of DriveU
              except the third party content and link to third party website on
              our Website/Application, if any.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Systematic retrieval of DriveU content to create or compile,
              directly or indirectly, a collection, compilation, database or
              directory (whether through robots, spiders, automatic devices or
              manual processes) without written permission from DriveU is
              prohibited.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              In addition, use of the content for any purpose not expressly
              permitted in this Terms of Use is prohibited and may invite legal
              action. As a condition of your access to and use of Services, you
              agree that you will not use the Website/Application to infringe
              the intellectual property rights of others in any way. DriveU
              reserves the right to terminate the account of a User upon any
              infringement of the rights of others in conjunction with use of
              the Service, or if DriveU believes that User’s conduct is harmful
              to the interests of DriveU, its affiliates, or other Users, or for
              any other reason in DriveU's sole discretion, with or without
              cause.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              11. USER ACCOUNTS, OFFERS AND PROMOTIONS:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU reserves the right to collect User data including name,
              contact information and other details to facilitate Services or
              use of its platform to avail Services. All information collected
              from the User are on a bona fide basis. Misuse and
              misrepresentation of identity or contact details will lead to
              automatic termination of Services or the use of the platform,
              without prior notice to such Users.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              User accounts bearing contact number and email IDs are created and
              owned by DriveU. Any promotional discounts and offers accumulated
              can be revoked without prior notice in the event of suspicious
              account activity or mala fide intent of the User.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              In the case where the system is unable to establish unique
              identity of a User against a valid mobile number or e-mail ID, the
              account shall be indefinitely suspended. DriveU reserves the full
              discretion to suspend a User's account in the above event and does
              not have the liability to share any account information
              whatsoever.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              For any other queries on DriveU and its services, please write in
              to us at care@driveu.in
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">12. LINKS TO THIRD PARTY SITES:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Links to third party sites are provided by the Application /
              Website as a convenience to Users and DriveU does not have any
              control over such sites i.e., content and resources provided by
              them.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU may allow Users access to content, products or services
              offered by third parties through hyperlinks (in the form of word
              link, banners, channels or otherwise) to such third party's
              website. You are cautioned to read such sites' terms and
              conditions and/or privacy policies before using such sites in
              order to be aware of the terms and conditions of your use of such
              sites. The Users acknowledge that DriveU has no control over such
              third party's site, does not monitor such sites, and DriveU shall
              not be responsible or liable to anyone for such third party site,
              or any content, products or services made available on such a
              site.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">13. TERMINATION:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU reserves the right to deny access to particular Users to
              any/all of its Services without any prior notice/explanation in
              order to protect the interests of DriveU and/or other Users to the
              Website/Application. DriveU reserves the right to limit, deny or
              create different access to the Website/Application and its
              features with respect to different Users.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We reserve the right to terminate your account or your access to
              the Website/Application immediately, with or without notice to
              you, and without liability: (i) if you have violated any of the
              Terms of Use; (ii) if you have furnished us with false or
              misleading information; (iii) pursuant to requests by law
              enforcement or other government agencies; (iv) in case of
              unexpected technical or security issues or problems; (v) in case
              of discontinuance or material modification to the Services (or any
              part thereof); and / or (vi) in case of interference with use of
              our Website/Application by others.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              In the event of termination by you or us, your account will be
              disabled and you will not be granted access to your account or any
              information or content contained in your account. You will not and
              not attempt to create another account for accessing and using the
              Website/Application without the written consent of DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              This Terms of Use shall remain in full force and effect while you
              have an account with us. Even after termination of your account
              with us, certain provisions of this Terms of Use will remain in
              effect, including but not limited to, Intellectual Property
              Rights, Prohibited Uses and Indemnification. You agree that we
              will not be liable to you or any third party for taking any of
              these actions.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Notwithstanding the termination of this Agreement, you shall
              continue to be bound by the terms of this Agreement in respect of
              your prior use of this Website/Application and all matters
              connected with, relating to or arising from such use.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              14. TERMS &amp; CONDITIONS FOR USE OF SERVICES:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Medium" }}>
            <span style={{ color: "#000000" }}>
              The following terms &amp; conditions shall apply to Users
              utilising the Services offered by DriveU:
            </span>
          </span>
        </span>
      </p>
      {/* <p>&nbsp;</p> */}

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The User shall pay the driver fare (as displayed in the DriveU
              App) or as agreed to in the terms of use as listed on the
              Application / Website, parking charges, additional night surcharge
              (where applicable), one way trips, toll charges and any fee or
              levy presently payable or hereinafter imposed by the law or
              required to be paid for availing of the Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The User agrees and accepts that the use of the Services provided
              by DriveU is at the sole risk of the User, and further
              acknowledges that DriveU disclaims all representations and
              warranties of any kind, whether express or implied. All Services
              are provided “AS IS”.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Medium" }}>
            <span style={{ color: "#000000" }}>
              The DriveU or the Driver has the right to refuse the Service in
              the following circumstances:
            </span>
          </span>
        </span>
      </p>
      {/* <p>&nbsp;</p> */}
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              if the User is found to be in an intoxicated state or is found
              misbehaving with other passengers or the Driver, or is causing a
              nuisance;
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              if the User is misusing, soiling or damaging any of the devices
              (technical/non-technical) of the Driver; and if the User asks the
              Driver to break any traffic/RTO/city police and/or government
              rules for any purpose The Driver has the right to refuse such a
              request by the User.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Without prejudice to the above, DriveU makes no representation or
              warranty that:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              the Services will meet the User’s requirements; and the Services
              will be uninterrupted, timely, secure, or error-free.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The information on this Website/Application is provided "AS IS"
              with no guarantee of completeness, accuracy, timeliness or of the
              results obtained from the use of this information, and without
              warranty of any kind, express or implied, including, but not
              limited to warranties of performance, merchantability and fitness
              for a particular purpose. Nothing herein shall to any extent
              substitute for the independent investigations and the sound
              technical and business judgment of the Users. In no event shall
              DriveU be liable for any direct, indirect, incidental, punitive,
              or consequential damages of any kind whatsoever with respect to
              the Service. Users of this site must hereby acknowledge that any
              reliance upon any content shall be at their sole risk. If any User
              misses his/her train or flight or bus, DriveU will not be liable
              for any compensation.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU is hereby authorized to use the location-based information
              provided by any of the telecommunication companies when the User
              uses the mobile phone to make a booking. The location-based
              information will be used only to facilitate and improve the
              probability of locating a Driver for the User.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU shall be entitled to disclose to any government body the
              particulars of the User in the possession, in its absolute
              discretion.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU shall be entitled at any time without giving any reason to
              terminate the booking of Drivers done by the User.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              If the User has any complaints in respect of the Services or the
              use of the Driver, the User has to inform DriveU of the same in
              writing within 24 hours of using the Driver or the Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU shall not be liable for any conduct of the Drivers.
              However, DriveU encourages you to notify it of any complaints that
              you may have against any Driver or the Services.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">15. TRIP PROTECTION PLAN:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We provide an optional Trip protection plan when you book a Driver
              using the App, at a nominal cost by opting for DriveU Secure(DU
              Secure). When you book a trip on the DriveU App, you will be asked
              to ‘Secure your booking’ prior to requesting a driver. This
              protection plan is valid through the duration of this trip. The
              details of coverage also provided on the App and specified in the
              last section of this T&amp;C. This Trip Insurance is not available
              if you book your driver through Website or Phone booking
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Should you choose or not able to procure this plan offered by
              DriveU, we are not responsible for any vehicle damage providing
              the Services.&nbsp;
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">16. INDEMNITY:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The User shall defend, indemnify and hold, DriveU, its affiliates,
              its licensors, and each of their officers, directors, other users,
              employees, attorneys and agents, harmless, from and against any
              and all claims, costs, damages, losses, liabilities and expenses
              (including attorneys' fees and costs) arising out of or in
              connection with the:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              violation or breach of the Terms of Use or any applicable law or
              regulation, whether or not referenced herein; violation of any
              rights of any third party, including the Driver via the
              Application and or the Website; and use or misuse of the
              Application/Website or Service.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">17. MISCELLANEOUS</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The information contained in this Website/Application is for
              general information purposes only. The information is provided by
              DriveU and while we endeavor to keep the information up to date
              and correct, we make no representations or warranties of any kind,
              express or implied, about the completeness, accuracy, reliability,
              suitability or availability with respect to the
              Website/Application or the information, products, services, or
              related graphics contained on the Website/Application for any
              purpose. Any reliance you place on such information is, therefore,
              strictly at your own risk.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              In no event will we be liable for any loss or damage, including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profits
              arising out of, or in connection with, the use of this
              Website/Application or Service.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Every effort is made to keep the Website/Application up and
              running smoothly. However, DriveU takes no responsibility for, and
              will not be liable for, the Website/Application being unavailable
              due to technical issues beyond our control.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The above mentioned Terms of Use and the Privacy Policy constitute
              the entire agreement between the User and DriveU with respect to
              access to and use of the Website/ Application and the Services
              offered by DriveU, superseding any prior written or oral
              agreements in relation to the same subject matter herein.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">18. JURISDICTION:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The courts of Bengaluru, India shall have the sole and exclusive
              jurisdiction in respect of any matters arising from the use of the
              Services offered by DriveU or the Terms of Use or any arrangement
              between DriveU and the User.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head text-center">2. DRIVEU WALLET</span>
          </span>
        </span>
      </p>
      <br />
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">1. OVERVIEW</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU Wallet is a mobile banking service provided by Humble
              Mobile Solutions Pvt. Ltd. (hereafter referred to as “DriveU”).
              DriveU has an inter alia aggregate payment gateway solutions
              service arrangement with PayU Payments Pvt. Ltd., having its
              registered office at F-130, GF Street, No.7, Pandav Nagar, New
              Delhi – 110091 (hereafter referred to as “PayU”).
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU Wallet allows DriveU customers to conduct financial
              transaction using a mobile device and software called DriveU app
              which is integrated with PayU software. PayU will act as an
              intermediary by creating a link between DriveU and the respective
              Acquiring Banks by means of software application and PayU site for
              enabling DriveU customers to make payment of customer charge on
              DriveU site for transactions carried through the payment
              mechanisms using the Acquiring Bank’s services. The Payments and
              Settlement Systems Act, 2007 is the primary law governing PayU
              payments systems in India, with the RBI as the body to supervise
              related matters.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DriveU Wallet is a Closed System Payment Instrument as defined by
              RBI. This payment instrument is issued by DriveU, for use for
              DriveU on-demand driver service only. These instruments do not
              permit cash withdrawal or redemption.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              2. TERMS AND CONDITIONS OF DRIVEU WALLET SERVICE FOR CUSTOMERS
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              provisions pertaining to electronic records in various statutes as
              amended by the Information Technology Act, 2000. This electronic
              record is generated by a computer system and does not require any
              physical or digital signatures. By registering at or merely
              browsing on Our Site you have agreed to be bound by all of these
              Terms and Conditions of Service for use and access of the Site.
              PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THESE TERMS AND
              CONDITIONS BEFORE YOU USE ANY OF THE SERVICES OFFERED ON THE SITE,
              BECAUSE YOU SHALL BE BOUND BY ALL THE TERMS AND CONDITIONS
              CONTAINED HEREIN. If You do not accept any of the Terms and
              Conditions, then please don’t use the Site or avail any of the
              Services being provided therein
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              YOU AGREEING TO THESE TERMS AND CONDITIONS SHALL OPERATE AS A
              BINIDNG AGREEMENT BETWEEN YOU AND DriveU IN RESPECT OF THE
              SERVICES OFFERED/AVAILED USING THE SITE
            </span>
          </span>
        </span>
        <br />
        &nbsp;
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">3. DEFINITIONS</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              All of the defined and capitalized terms in these T&amp;Cs will
              have the meaning assigned to them here below:
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "Act" means the Payment and Settlement Systems Act, 2007 and any
              amendment, modification or re-enactment of the same, or any other
              succeeding enactment for the time being in force.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Applicable Laws” shall mean and include all applicable statutes,
              enactments, acts of legislature or the Parliament, laws,
              ordinances, rules, by-laws, regulations, notifications,
              guidelines, policies, directions, directives and orders of any
              governmental authority, tribunal, board, or a court and applicable
              international treaties and regulations, in force at the relevant
              time in India.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Charges” would mean any charges and fees levied by DriveU to the
              registered customer for the use of the DriveU Wallet service, a
              full list of such charges would be available on the DriveU
              website, and may change from time to time. All registered users
              and merchants would be liable to pay these charges for using the
              DriveU service. DriveU will be entitled to recover the service
              charges and the other dues at source from and out of the DriveU
              Coupons lying to the credit of the Customer with the Service
              Provider.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The Customer will also be liable for any Government taxes or
              levies which may be payable on such service charges levied by
              DriveU. DriveU is engaged in the business of providing the
              services to both, i.e. the buyer and the vendor of the goods,
              merchandise and the necessaries, in the course of which DriveU
              would provide a common platform from which the Customer could
              secure the purchase and the supply of goods etc. and also make the
              arrangement to secure to the Merchant the payment of the price
              therefore in terms of the Merchant redeeming the DriveU electronic
              coupons issued by the DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "Customer" means the holder of DriveU, an applicant who registers
              for the DriveU services and includes his/her heirs and assignees,
              be it a user, or dealer who sells or resells DriveU coupons.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Merchant” shall mean an individual or organization that accepts
              payment for their products/ services through DriveU and has
              executed a Merchant Agreement with DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "Account" refers to the account created by Customer on the Site.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “DriveU Wallet” or “Wallet” means a pre-paid instrument issued by
              DriveU and/or issued under a cobranding arrangement with an
              entity.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Lifetime Balance” means funds added by You via recharging the
              DriveU Wallet
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Promotional Balance” means funds added by DriveU in Your DriveU
              Wallet.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "RBI" means the Reserve Bank of India.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "RBI Regulations" means the Act and regulations made thereunder,
              the Issuance and Operation of Pre-paid Instruments in India (RBI)
              Directions, 2009 and any notifications, master circulars,
              instructions or guidelines issued and updated by the RBI from time
              to time, in relation to the same.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "Services" includes the facilitation of payments to the Merchant
              for the products/services availed by the Customer through DriveU
              and enabling of receipt of such payments by the Merchant.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Site” shall mean www.DriveU.in and such other websites and mobile
              applications as may be developed by DriveU from time to time.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "Terms and Conditions of Service" or "T&amp;Cs" refers to the
              terms and conditions that are available at the Site for the use of
              Services.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Transaction" shall mean every payment request/order placed by the
              Customer using DriveU Wallet.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Wallet to Wallet Transfer” is service is not available wherein
              You can transfer funds from Your DriveU Wallet to another DriveU
              Wallet, provided that both Wallets (i.e. transferee Wallet and
              transferor Wallet) are held with the same entity which has a
              co-branding arrangement with DriveU Wallet.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “Wallet to Bank Transfer” is service available wherein You can
              transfer funds from Your DriveU Wallet to a designated savings or
              current bank accounts.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "We/we", "Our/our" or "Us/us" “Service Provider” refers to DriveU
              and shall include its successors and assignees.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              "You/you", “Your/your” or “Yourself/yourself” shall mean reference
              to Customer or his representative and any other user accessing the
              Site.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “DriveU Wallet” means PayU Payments Pvt. Ltd., having its
              registered office at F-130, GF Street, No.7, Pandav Nagar, New
              Delhi – 110091 and corporate office at 4th Floor, Pearl Tower,
              Plot No 51, Institutional Area, Sector 32, Gurgaon-122001,
              Haryana, India (hereafter referred to as “PayU”). PayU is an
              authorized payment and settlement systems operator under the
              provisions of the Act and has been granted approval by the RBI to
              issue co-branded instrument.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              “DriveU Coupon(s) means a coupon issued by DriveU and/or a coupon
              issued by DriveU under a cobranding arrangement with an entity.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              For the purpose of these T&amp;Cs wherever the context so requires
              "You" or "User" shall mean any natural or legal person who
              accesses the Site, including holders of DriveU and merchants which
              allows its customers to purchase goods and services using DriveU
              as a payment option.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              1A (i) Service Provider has devised an electronic payment system
              to be known as “DriveU Wallet” which would be routed through the
              mobile phones, internet medium and other mediums in the future.
              This system will consist of a closed user-customer group to be
              known as Customer in which the vendors and suppliers of various
              goods and services to be known as Merchants, will participate. The
              Customer is one of such Users. (ii) DriveU Wallet is a service by
              the Service Provider to the Customers and the Merchants which will
              primarily provide convenience and security to the Customers and
              the Merchant for making payments.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              (iii)Through DriveU Wallet, both, the users and the Merchants will
              be able to secure all kinds of payment transactions, including,
              but not limited to online transactions, online payments for the
              home delivery segment, bill payments and so on.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              (iv) In consideration of the payment of money by the Customer to
              be made to DriveU from time to time, the Service Provider will
              issue to the Customer DriveU Wallet Coupons which shall be in
              digital form. Customer will be entitled to purchase the DriveU
              Wallet Coupons several processes, including, but not limited to
              dropping a cheque in any of the DriveU Wallet Collection Boxes,
              using Credit/Debit Card online to charge the Customer’s account
              with the Service Provider as also by giving cash to any of the
              authorised dealers of DriveU in exchange for reloading their
              account with DriveU Wallet coupons.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              (v) In consideration of the Customer making payment of money to
              the DriveU or to any of its authorised dealers, the Service
              Provider will issue and/or cause to be issued to the Customer the
              DriveU Wallet Coupons in the physical and/or the digital forms
              depending upon the manner the Customer orders therefore; Service
              Provider has for the time being arranged for the issue of the
              DriveU Wallet Coupons through the three modes i.e. crediting the
              Customer’s DriveU Wallet account with the coupons of the requisite
              denominations depending upon the amount paid through any of the
              processes: using the Credit/Debit Card Online to charge the
              Customer’s account with the Service Provider, payment to any of
              the authorized dealers of the DriveU or the purchase of the
              prepaid card from any of the authorized dealers; (vi) In case the
              Customer purchases the DriveU Wallet Coupons in terms of the
              prepaid card, he will be allotted a unique PIN which the Customer
              will communicate through the SMS or or through the DriveU website
              to DriveU Server against which his account will be loaded with
              equivalent DriveU Wallet coupons corresponding to the value
              mentioned on such prepaid card. (vii) DriveU Wallet is not and
              shall not be a domestic remittance service and as such no user
              will be entitled to redeem and/or cause to be redeemed the same
              for cash. DriveU Wallet Coupons are not and shall not be a legal
              tender within the meaning of any law including The Reserve Bank of
              India Act, 1934. DriveU Wallet coupons can only be redeemed by
              registered Merchants by means of a transfer of equivalent value of
              their coupon balance to their bank account, or by an account payee
              cheque, and such payment will be issued by DriveU after deducting
              any service charges.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              (viii) Service Provide will from time to time, depending upon the
              necessities and the expediencies of the circumstances, add to
              and/or discontinue any of the, aforesaid modalities. (ix) It is
              hereby further agreed by and between the parties that the Customer
              will not under any circumstances be entitled to seek the refund of
              any of the DriveU Wallet Coupons, it being clearly agreed and
              understood by and between the parties that the DriveU being only a
              one way service provider will recognize only the debit
              instructions to be given by its user, i.e. the Customer. (x)
              DriveU Wallet is being offered as a service using mobile phones
              using SMS and through the DriveU website, and will be offered by
              other methods as intimated to users over time. However, different
              mobile service providers may have made different restrictions /
              limitations in their packages offered to their mobile subscribers.
              DriveU is not responsible for any such limited service offering
              from any Mobile service provider or for such differences in
              between any two or more such providers. You may be charged a fee
              for using SMS and mobile services by your mobile operator, and
              DriveU is not responsible for such charges. DriveU Wallet coupons
              are non-refundable electronic credits that can be used to make
              transactions with registered merchants, but cannot be redeemed for
              cash. Users may gift DriveU Wallet coupons/vouchers to other
              users, however, only registered merchants can redeem their DriveU
              Wallet credits by requesting either a transfer to their bank
              account, or by an account payee cheque.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              1B Services NOT Offered by DriveU Wallet
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">1.Online Gambling</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.Remittance services</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              3.No other use of the DriveU Wallet service that may be prohibited
              by law.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.4 Interpretation.</span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any reference to the singular includes a reference to the plural
                and vice versa, unless explicitly provided for otherwise; and
                any reference to the masculine includes a reference to the
                feminine and vice versa.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Headings and captions are used for convenience only and will not
                affect the interpretation of these T&amp;Cs.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any reference to a natural person will, unless repugnant to the
                context, include his heirs, executors and permitted assigns.
                Similarly, any reference to a juristic person such as DriveU
                will, unless repugnant to the context, include its affiliates,
                successors and permitted assigns.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.5 Eligibility.</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              a) You will be "Eligible" to use Our Services only when You
              fulfill the following conditions:
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              b) have attained at least 18 (eighteen) years of age;
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              c) are competent to enter into a contract under the Applicable
              Laws.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              d) You can avail the Services only if You fulfill the conditions
              as mentioned in a) to above.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              e) If You are not Eligible, please immediately abandon any and all
              attempts to register with Us.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              f) We rely completely on the information provided by You and We
              shall not be held liable if You or anyone who uses Your Account or
              DriveU is not Eligible.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              g) As a minor if You wish to avail the Services, such Services may
              be availed by your legal guardian or parents who have registered
              as users on the Site.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              h) Notwithstanding anything contained under these T&amp;Cs, We
              reserve the right to terminate Your membership and refuse to
              provide You with access to the Site or provide Services if it is
              brought to Our notice or if it is discovered that You are under
              the age of 18 years or are incompetent to contract as per
              Applicable Laws.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.6 Registration.</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              a) You can register as a Customer, by providing a valid phone
              number, Your name, valid and functional e-mail address and any
              other additional information that RBI Regulations may prescribe to
              be necessary to satisfy Know Your Customer norms ("KYC Norms"), or
              other information as We may deem fit. (“Registration Data”).
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              b) You shall ensure that the Registration Data provided by You
              must be accurate, complete, current and true. We shall bear no
              liability for false, incomplete, old or incorrect Registration
              Data provided by You.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              c) You hereby expressly consent to receive communications from Us
              through Your registered phone number and/or e-mail id. You consent
              to be contacted by Us via phone calls/SMS notifications. You agree
              that any communication so received by You from Us will not amount
              to spam, unsolicited communication or a violation of Your
              registration on the national do not call registry.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              d) You are solely responsible for maintaining the confidentiality
              of Your Registration Data and will be liable for all activities
              and Transactions that occur through Your Account and DriveU,
              whether initiated by You or any third party. The password and
              other details of the Account should not be shared with any third
              party. Your Account is non-transferable and is not capable of
              being sold to a third party. We shall not be liable for any loss
              that You may incur as a result of someone else using Your password
              or Account or accessing Your device, either with or without Your
              knowledge.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              e) In the event of any change in the said information, You agree
              to promptly update Your Account information to ensure that the
              communications We intend to send to You are promptly delivered to
              You and are not sent to any other entity/third party.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              f) We reserve the right to suspend or terminate Your Account with
              immediate effect and for an indefinite period, if We have a reason
              to believe that the Registration Data or any other data provided
              by You is incorrect or false, or that the security of Your Account
              has been compromised in any way. We reserve the right to suspend
              or terminate Your Account for reasons including and not limited to
              any fraud charges against You raised by the bank or concerned
              authorities and We also reserve the right to share Your
              information with them for the purposes of investigation.
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              g) We allow You to open only one Account in association with
              Registration Data provided by You. 4.8 In case of any unauthorized
              use of Your Account please immediately reach Us at care@DriveU.in
            </span>
          </span>
        </span>
        <br />
        <br />
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              h) In case, You are unable to access Your Account or DriveU
              Wallet, please inform Us at care@DriveU.in and make a written
              request for blocking Your Account. We will not be liable for any
              unauthorized transactions made through Your Account prior to You
              making a request in writing for blocking and We shall require at
              least 72 (Seventy Two) hours to process your request unless in
              case of force majeure events which are beyond Our reasonable
              control.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.7 Use of Site</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You agree, undertake and confirm that Your use of Site shall be
                strictly governed by the following binding principles:
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall not host, display, upload, modify, publish, transmit,
                update or share any information that:
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                belongs to another person and to which You do not have any right
                to; or interferes with another user's use and enjoyment of the
                Site or any other individual's user and enjoyment of similar
                services; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                that is harmful, harassing, blasphemous, defamatory, obscene,
                pornographic, libelous, invasive of another's privacy, hateful,
                or ethnically objectionable, disparaging, relating or
                encouraging money laundering or gambling, or otherwise unlawful
                in any manner whatever, or unlawfully threatening or unlawfully
                harassing including but not limited to "indecent representation
                of women" within the meaning of the Indecent Representation of
                Women (Prohibition) Act, 1986, of another person; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                misleading in any way; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                is patently offensive to the online community, such as sexually
                explicit content, or content that promotes obscenity,
                pedophilia, racism, bigotry, hatred or physical harm of any kind
                against any group or individual; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                involves the transmission of "junk mail," "chain letters," or
                unsolicited mass mailing or "spamming"; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                infringes upon or violates any third party's rights, (including,
                but not limited to, intellectual property rights, rights of
                privacy (including without limitation unauthorized disclosure of
                a person's name, email address, physical address or phone
                number) or rights of publicity]; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                contains restricted or password-only access pages, or hidden
                pages or images (those not linked to or from another accessible
                page); or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                provides material that exploits people in a sexual, violent or
                otherwise inappropriate manner or solicits personal information
                from anyone; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                provides instructional information about illegal activities such
                as making or buying illegal weapons, violating someone's
                privacy, or providing or creating computer viruses; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                contains video, photographs, or images of another person without
                his or her express written consent and permission or the
                permission or the consent of his/ her guardian in the case of
                minor; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                tries to gain unauthorized access or exceeds the scope of
                authorized access (as defined herein and in other applicable
                Codes of Conduct or End User Access and License Agreements) to
                the Sites or to profiles, blogs, communities, account
                information, bulletins, friend request, or other areas of the
                Sites or solicits passwords or personal identifying information
                for commercial or unlawful purposes from other users; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                engages in commercial activities and/or sales without Our prior
                written consent such as contests, sweepstakes, barter,
                advertising and pyramid schemes, or the buying or selling of
                "virtual" items related to the Sites. Throughout these T&amp;Cs,
                Our "prior written consent" means a communication coming from
                Our authorized representative, specifically in response to Your
                request, and specifically addressing the activity or conduct for
                which You seek authorization; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                interferes with another user's use and enjoyment of the Site or
                any other individual's user and enjoyment of similar services;
                or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                refers to any website or URL that, in Our sole discretion,
                contains material that is inappropriate for the Site, contains
                content that would be prohibited or violates the letter or
                spirit of these T&amp;Cs; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                harm minors in any way; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                is fraudulent or involve the sale of counterfeit or stolen
                items; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                violates any law for the time being in force; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                deceives or misleads the addressee/ users about the origin of
                such messages or communicates any information which is grossly
                offensive or menacing in nature; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                impersonate another person; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource; or contains any Trojan
                horses, worms, time bombs, cancelbots, easter eggs or other
                computer programming routines that may damage, detrimentally
                interfere with, diminish value of, surreptitiously intercept or
                expropriate any system, data or personal identifiable
                information or attempt to interfere with the proper working of
                the Site or any transaction being conducted on the Site; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognizable
                offence or prevents investigation of any offence or is insulting
                to any other nation; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                is false, inaccurate or misleading; ordirectly or indirectly,
                offer, attempt to offer, trade or attempt to trade in any item,
                the dealing of which is prohibited or restricted in any manner
                under the provisions of any applicable law, rule, regulation or
                guideline for the time being in force; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                creates liability for Us or cause Us to lose (in whole or in
                part) the services of Our ISPs or other suppliers.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall not use any "deep-link", "page-scrape", "robot",
                "spider" or other automatic device, program, algorithm or
                methodology, or any similar or equivalent manual process, to
                access, acquire, copy or monitor any portion of the Site or any
                Content, or in any way reproduce or circumvent the navigational
                structure or presentation of the Site or any Content, to obtain
                or attempt to obtain any materials, documents or information
                through any means not purposely made available through the Site.
                We reserve the right to bar any such activity.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall not probe, scan or test the vulnerability of the Site
                or any network connected to the Site nor breach the security or
                authentication measures on the Site or any network connected to
                the Site. You may not reverse look-up, trace or seek to trace
                any information on any other user of or visitor to the Site, or
                any other customer of DriveU, including any of Our account not
                owned by You, to its source, or exploit the Site or any service
                or information made available or offered by or through the Site,
                in any way where the purpose is to reveal any information,
                including but not limited to personal identification or
                information, other than your own information, as provided for by
                the Site.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You agree that You will not take any action that imposes an
                unreasonable or disproportionately large load on the
                infrastructure of the Site or Our systems or networks, or any
                systems or networks connected to the Site.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You may not forge headers or otherwise manipulate identifiers in
                order to disguise the origin of any message or transmittal You
                send to Us on or through the Site or any service offered on or
                through the Site. You may not pretend that You are, or that You
                represent, someone else, or impersonate any other individual or
                entity.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You may not use the Site or any Content for any purpose that is
                unlawful or prohibited by these T&amp;Cs, or to solicit the
                performance of any illegal activity or other activity that
                infringes the rights of DriveU or others.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall not use the Services or Site in any manner that could
                damage, disable, overburden, block or impair any of the server
                connected to the Site. You may not attempt to gain unauthorized
                access to the Services through hacking, password mining or any
                other means.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You may not reverse engineer, decompile and disassemble any
                software used to provide the Services.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall not make any negative, denigrating or defamatory
                statement(s) or comment(s) about Us or the brand name or domain
                name used by Us or otherwise engage in any conduct or action
                that might tarnish the image or reputation, of DriveU or its
                merchants on platform or otherwise tarnish or dilute any of Our
                trade or service marks, trade name and/or goodwill associated
                with such trade or service marks, trade name as may be owned or
                used by Us.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You have the option to request Us to send You information
                regarding services, discounts and promotions provided by Us. If
                you require Us to provide You information from Us regarding
                offers, discounts and promotions relating to the Services
                availed by You, click "Yes", otherwise click "No". If you click
                "Yes", We or an authorised representative, shall provide the
                above information to You by way of an SMS or email to Your
                registered mobile number/registered email id. You also have the
                option to discontinue receiving such information at any point of
                time. To discontinue receiving such information, You may at any
                point of time visit the specific link provided in Our Site to
                discontinue the same. Solely to enable Us to use the information
                so that We are not violating any rights You might have in Your
                Information, You agree to grant Us a non-exclusive, worldwide,
                perpetual, irrevocable, royalty-free, sub-licensable (through
                multiple tiers) right to exercise the copyright, publicity,
                database rights or any other rights You have in Your
                Information, in any media now known or not currently known, with
                respect to Your Information. We will only use Your Information
                in accordance with these T&amp;Cs and Our Privacy Policy.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall not engage in advertising to, or solicitation of,
                other users of the Site to buy or sell any products or services,
                including, but not limited to, services related being displayed
                on or related to the Site. You may not transmit any chain
                letters or unsolicited commercial or junk email to other users
                via the Site. It shall be a violation of these T&amp;Cs to use
                any information obtained from the Site in order to harass,
                abuse, or harm another person, or in order to contact, advertise
                to, solicit, or sell to another person outside of the Site
                without Our prior explicit consent. In order to protect Our
                users from such advertising or solicitation, We reserve the
                right to restrict the number of messages or emails which a user
                may send to other users in any 24-hour period which We may deem
                appropriate in Our sole discretion.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You understand that We have the right at all times to disclose
                any information (including the identity of the persons providing
                information or materials on the Site) as necessary to satisfy
                any law, regulation or valid governmental request. This may
                include, without limitation, disclosure of the information in
                connection with investigation of alleged illegal activity or
                solicitation of illegal activity or in response to a lawful
                court order or subpoena. In addition, We can (and You hereby
                expressly authorize Us to) disclose any information about You to
                law enforcement or other government officials, as We, in Our
                sole discretion, believe necessary or appropriate in connection
                with the investigation and/or resolution of possible crimes,
                especially those that may involve personal injury.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We reserve the right, but have no obligation, to monitor the
                materials posted on the Site. We shall have the right to remove
                or edit any Content that in its sole discretion violates, or is
                alleged to violate, any applicable law or either the spirit or
                letter of these T&amp;Cs. Notwithstanding this right of DriveU,
                YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS
                YOU POST ON THE SITE AND IN YOUR PRIVATE MESSAGES. Please be
                advised that such Content posted does not necessarily reflect
                Our views. In no event shall We assume or have any
                responsibility or liability for any Content posted or for any
                claims, damages or losses resulting from use of Content and/or
                appearance of Content on the Site. You hereby represent and
                warrant that You have all necessary rights in and to all Content
                You provide and all information it contains and that such
                Content shall not infringe any proprietary or other rights of
                third parties or contain any libelous, tortious, or otherwise
                unlawful information.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Your correspondence or business dealings with, or participation
                in promotions of, third party advertisers found on or through
                the Site, including payment and delivery of related goods or
                services, and any other terms, conditions, warranties or
                representations associated with such dealings, are solely
                between You and such third party advertiser. We shall not be
                responsible or liable for any loss or damage of any sort
                incurred as the result of any such dealings or as the result of
                the presence of such third party advertisers on the Site.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                It is possible those other users (including unauthorized users
                or “hackers”) may post or transmit offensive or obscene
                materials on the Site and that You may be involuntarily exposed
                to such offensive and obscene materials. It also is possible for
                others to obtain personal information about You on the public
                forum due to your use of the Site, and that the recipient may
                use such information to harass or injure You. We do not approve
                of such unauthorized uses but by using the Site. You acknowledge
                and agree that We shall not responsible for the use of any
                personal information that You publicly disclose or share with
                others on the Site. Please carefully select the type of
                information that You publicly disclose or share with others on
                the Site, or from the Site on other social platforms (eg, but
                not limited to Facebook).
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We shall have all the rights to take necessary action and claim
                damages that may occur due to Your involvement/participation in
                any way on Your own or through group/s of people, intentionally
                or unintentionally in DoS/DDoS (Distributed Denial of Services).
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                DriveU’s performance of this T&amp;Cs is subject to existing
                laws and legal processes of Government of India, and nothing
                contained in the T&amp;C is in derogation of DriveU's right to
                comply with law enforcement requests or requirements relating to
                your use of this service or information provided to or gathered
                by DriveU with respect to such use. You agree that DriveU may
                provide details of your use of the service to regulators or
                police or to any other third party, or in order to resolve
                disputes or complaints which relate to the service, at DriveU’s
                complete discretion.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                This agreement constitutes the entire agreement between the
                Customer and DriveU with respect to the DriveU Wallet payment
                service and it supersedes all prior or contemporaneous
                communications and proposals, whether electronic, oral, or
                written, between the customer and DriveU with respect to this
                service. A printed version of the T&amp;C and of any notice
                given in electronic form shall be admissible in judicial or
                administrative proceedings based upon or relating to the T&amp;C
                to the same extent and subject to the same conditions as other
                business documents and records originally generated and
                maintained in printed form.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Each user may be issued a personal identification number (PIN)
                to enable use of DriveU services for accessing his/her Account
                on Internet and also for availing any privilege, benefit or
                service that may be offered by DriveU or to make transactions
                using SMS. The PIN will be communicated to the Cardholder
                entirely at his/her risk, who shall not disclose the PIN to any
                person and shall take all possible care to avoid its discovery
                by any person. The user shall be liable for all transactions
                made with the use of the PIN, whether with or without the
                knowledge of the Cardholder. DriveU reserves the right to refuse
                any transactions if it believes that the PIN is being misused or
                being used without appropriate authorization.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.8 Contents Posted on Site</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                All text, graphics, user interfaces, visual interfaces,
                photographs, trademarks, logos, sounds, music, artwork and
                computer code (collectively, "Content"), including but not
                limited to the design, structure, selection, coordination,
                expression, "look and feel" and arrangement of such Content,
                contained on the Site is owned, controlled or licensed by or to
                Us, and is protected by trade dress, copyright, patent and
                trademark laws, and various other intellectual property rights
                and unfair competition laws.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Except as expressly provided in these T&amp;Cs, no part of the
                Site and no Content may be copied, reproduced, republished,
                uploaded, posted, publicly displayed, encoded, translated,
                transmitted or distributed in any way (including "mirroring") to
                any other computer, server, website or other medium for
                publication or distribution or for any commercial enterprise,
                without Our express prior written consent.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You may use information on Our services purposely made available
                by Us for downloading from the Site, provided that You:
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                do not remove any proprietary notice language in all copies of
                such documents; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                use such information only for Your personal, non-commercial
                informational purpose and do not copy or post such information
                on any networked computer or broadcast it in any media; or
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                make no modifications to any such information; or do not make
                any additional representations or warranties relating to such
                documents.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You shall be responsible for any notes, messages, e-mails,
                billboard postings, photos, drawings, profiles, opinions, ideas,
                images, videos, audio files or other materials or information
                posted or transmitted to the Sites (“Posted Content”). Such
                Posted Content will become Our property and You grant Us the
                worldwide, perpetual and transferable rights in such Posted
                Content. We shall be entitled to, use the Posted Content or any
                of its elements for any type of use forever, including but not
                limited to promotional and advertising purposes and in any media
                whether now known or hereafter devised, including the creation
                of derivative works that may include Posted Content. You agree
                that any Posted Content may be used by Us in the manner that We
                deem fit, consistant with Applicable Laws and You are not
                entitled to any payment or other compensation for such use of
                Posted Content by Us.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.9 Use of DriveU Wallet</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Upon successful registration, You can start availing Our
                Services through DriveU Wallet.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                The features of DriveU Wallet are as follows:
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Currency of issue. Indian Rupees only.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Limits of re-loading (i) up to Rs.10,000/- by accepting Your
                minimum details provided the amount outstanding at any point of
                time in Your DriveU Wallet does not exceed Rs 10,000/- and the
                total value of reloads during any given month also does not
                exceed Rs 10,000/-.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Transferability: RBI Regulations prohibit transfer of the
                Accounts and/or DriveU Wallets from the Customer to any third
                party. Validity period. 3 years from the date of
                activation/issuance to the holder.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Maximum permissible period of non-use of the DriveU Wallet
                (“Permissible Period.”) 6 months either from the date of
                issuance or from the date of the last use of Services occurred.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We reserve the right to forfeit the balance in Your Account at
                the time of expiry of the DriveU Wallet or in case the Wallet is
                not used beyond the Permissible Period. We shall intimate You
                about such forfeiture and expiry of the DriveU Wallet or the
                Permissible Period 30 days’ prior to expiry of Your Account or
                the Permissible Period, before forfeiting outstanding balances
                in the Account if any. The intimation shall be sent to
                registered e-mail id or phone number. We shall not be held
                responsible or liable for any failure of delivery of the
                intimation. Further, You agree and acknowledge that You shall
                have no claim against Us for the balance forfeited from your
                DriveU Wallet.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                All Transactions shall be subject to the amount of the
                outstanding balance existing in the DriveU Wallet at the time of
                making a transaction, Domestic Money Transfer Guidelines as
                issued by RBI or any other limit as may prescribed by Us from
                time to time.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.10 Charges</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              There are no Charges and fees levied by DriveU Wallet to the
              registered customer for the use of the DriveU Wallet service. We
              reserve the right to change Our policies and any Charges from time
              to time. In particular, We may at Our sole discretion introduce
              new services and modify some or all of the existing Services
              offered on the Site. In such an event, We reserve, without notice
              to You, the right to introduce fees for the new services offered
              or amend/introduce fees for existing Services, as the case may be.
              Changes to the Fee and related policies shall automatically become
              effective immediately once implemented on the Site.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.11 Loading of DriveU Wallet</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You can reload DriveU Wallet referred to as Lifetime Balance by
                using only those modes of the payment as enabled by Us. These
                payment modes may be revised by Us from time to time. Lifetime
                Balance is governed by RBI rules as specified in Section 7.1 of
                this Agreement.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                DriveU may from time to time add funds into Your DriveU Wallet
                which is referred to as Promotional Balance. Promotional Balance
                is a fund that is added to Your DriveU Wallet via marketing
                promotions such as cashback, referral and other rewards.
                Promotional Balance expires after 30 days.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                When You opt to load money into Your DriveU Wallet You will be
                required to provide certain information ("Payment Details") for
                the purpose of reloading. You acknowledge and agree that by
                providing such Payment Details You authorise Us to reload Your
                DriveU Wallet. The Payment Details You provide must be true,
                complete, current and accurate. We do not bear any liability for
                the consequences if You provide false, old, incomplete or
                incorrect Payment Details.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You represent and warrant that You are legally and fully
                entitled to use any debit card, credit card, bank or any other
                mode that You may use to load funds into Your DriveU Wallet. We
                shall not be held responsible in case You don’t have sufficient
                funds or authorised access to use such mode to load Your DriveU
                Wallet.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You hereby authorize Us to hold, receive and disburse funds in
                Your DriveU Wallet in accordance with any payment instructions
                We receive from You. Among other things, Your authorization
                permits Us to debit or credit the bank account associated with
                Your Payment Details. Your authorization will remain in effect
                as long as You maintain an Account with Us.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.12 Wallet to Merchant Transfer</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                When We receive payment instructions from You to pay a Merchant,
                You authorize and order Us to commit Your payment to that
                Merchant. This authorization will remain in effect as long as
                You maintain an Account with Us.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                In the event that You have any dispute with the Merchant in
                relation to the goods or services provided to You by a Merchant
                for any reason, We recommend that You report the matter to the
                concerned Merchant in question. Neither DriveU shall be liable
                for the goods nor services provided by Merchants and cannot be
                made a party to the disputes between a Merchant and You.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.13 Wallet to Wallet Transfer</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Please note that Wallet to Wallet transfer facility is not
              available at present. We shall notify You about the terms and
              conditions governing it as and when the same are available.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.14 Wallet to Bank Transfer</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Please note that the Wallet to Bank transfer facility is not
              available at present. We shall notify You about the terms and
              conditions governing it as and when the same are available.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.15 Refunds</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                In the event that You desire a refund on any amount that has
                been debited from Your DriveU Wallet, please email
                care@DriveU.in and make a refund request clearly explaining the
                circumstances of your refund request.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Where We determine that a refund request is valid, We shall make
                reasonable efforts to grant the refund request and return the
                requisite funds to Your DriveU Wallet.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Please note that RBI regulations do not permit Us to refund any
                balance existing in Your DriveU Wallet back to You since this is
                a Closed Wallet.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                In case Your DriveU Wallet is erroneously loaded / reloaded,
                after assessing the validity of the written request from You, We
                shall process to credit the funds to the same source from where
                these were received.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We will make reasonable efforts to respond to Your refund
                request at the earliest. Please note that We will not be
                responsible for delays, which may be caused by any third parties
                such as banks and Merchants, on whom We rely while processing
                Your refund request or delay caused in the case of any or any
                force majeure events which are beyond Our reasonable control.
                Therefore, We bear no liability for the processing of the refund
                requests.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.16 Chargebacks.</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You may have the right to initiate a chargeback request in
                accordance with the terms and conditions of Your debit or credit
                card scheme or your bank account. Please note that Your
                chargeback request will be processed by Your debit/ credit card
                provider or your bank and not by Us. Therefore, We bear no
                liability for the processing of the chargeback requests.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We reserve the right to review Your Account and transaction
                history pursuant to a chargeback request, in order to determine
                the occurrence of fraud. If We have any reason to believe that
                there has been fraudulent activity on Your Account, We reserve
                the right to terminate, block or suspend Your Account with
                immediate effect.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.17 Non-Working Days.</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We are reliant on third parties such as banks to deliver timely
                Services. Since, We cannot control the actions of such third
                parties, We will not be responsible to render Services or
                process payments or refunds on the following days (“Non-Working
                Days”):
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                days which are declared as holidays by the RBI, or any other day
                which is declared a holiday by Us.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                days including Saturdays and Sundays and declared as bandhs or
                festivals in any State or Union Territory in India. We will
                continue to render Services which do not involve that particular
                State or Union Territory in which a bandh or festival has been
                declared on that day.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              2.18 Intellectual Property Protection
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                All trademarks, brands and service marks used in connection with
                the Site or Services offered by are owned by DriveU is DriveU’s
                property; DriveU owns all copyrights and intellectual property
                rights and database rights in connection with the Services and
                the Site. For use of any third party's intellectual property,
                You need to get permission directly from the owner of the
                intellectual property for any use. Third party trademarks may
                appear on this Site and all rights therein are reserved to the
                registered owners of those trademarks.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You agree to abide by laws regarding copyright, trademark,
                patent, and trade secret ownership and use of intellectual
                property, and You shall be solely responsible for any violations
                of any laws and for any infringements of any intellectual
                property rights caused by use of the interface/Site.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                DriveU is the sole and absolute owner of all and whatsoever the
                intellectual property rights in the trade mark DriveU and the
                design in respect thereof and that DriveU will be entitled to
                take any legal action against any person invading and/or
                encroaching upon the said rights of DriveU.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.19 Suspicious Transactions.</span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                If We have a reason to believe that any payment instructions
                made on Your Account have been fraudulently made, We will
                suspend and deny You access to Your Account in order to
                investigate and report the suspicious activity. Such suspension
                will continue in effect till the conclusion of the
                investigation. In case it is established that DriveU Wallet is
                loaded / reloaded through fraudulent means, We reserve the right
                to either credit back the funds to the same source from where
                these were received or to forfeit the funds.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Please note that We are reliant on a number of third parties
                such as banks and Merchants while conducting the investigation.
                However, We will make every reasonable effort to conclude the
                investigation at the earliest.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Upon conclusion of the investigation and based on its results,
                We may be required to terminate Your Account with immediate
                effect.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.20 Other Businesses</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We do not take responsibility or liability for the actions,
              products, content and services on the Site, which are linked to
              affiliates and / or third party websites using Our APIs or
              otherwise. In addition, We may provide links to the third party
              websites of affiliated companies and certain other businesses for
              which, We assume no responsibility for examining or evaluating the
              products and services offered by them, and We do not warrant the
              offerings of, any of these businesses or individuals or the
              content of such third party website(s). We do not in any way
              endorse any third party website(s) or content thereof.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>2.21 Links</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We welcome links to this Site. You may establish a hypertext link
              to the Site, provided that the link does not state or imply any
              sponsorship or endorsement of your site by Us. You must not use on
              your site or in any other manner any trademarks, service marks or
              any other materials appearing on the Site, including any logos or
              characters, without Our express written consent and the owner of
              the mark or materials. You must not frame or otherwise incorporate
              into another third party website or present in conjunction with or
              juxtaposed against such a website any of the content or other
              materials on the Site without Our prior written consent.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              2.22 Termination, Deactivation and Expiry of the Account.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We reserve the right to modify, terminate or suspend Services to
              You at any time without prior notice due to any changes in
              internal policy or due to RBI Regulations or the Applicable Laws
              or any breach of these T&amp;Cs by You or for any reason
              whatsoever. You can terminate Your Account by submitting a request
              to Us at care@DriveU.in. However, in either case, all DriveU
              Wallet Coupon balances in Your account shall stand voided, and no
              refund will be provided. We will make every effort to respond to
              Your request for termination at the earliest. You will remain
              responsible for all Transactions that occurred prior to
              termination of Your Account. Please note that RBI does not allow
              Us to return the funds outstanding in Your DriveU Wallet,
              therefore in the event of termination of your Account, outstanding
              balance in Your DriveU Wallet shall be forfeited. However in case
              of termination of your Account, We may provide You with an option
              to transfer funds from your DriveU Wallet to a designated bank
              account in terms of Paragraph 12 above, as and when the facility
              of Wallet to Bank Transfer is available. If a User violates the
              terms and conditions of use, DriveU Wallet reserves the right to
              deactivate all such user registrations and cancel any or all
              DriveU Wallet Coupons balance without any notice or refund.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              The DriveU Wallet shall expire in the event of the occurrence of
              one of the following circumstances:
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Expiry of a period of 3 years from the date on which You
                successfully registered for an Account. DriveU Wallet can be
                renewed for a period of 1 year at the sole discretion of DriveU
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Non-use of the DriveU Wallet for a time period beyond 6 months
                from the date of the issuance and Non-use of the DriveU Wallet
                for a time period beyond 6 months from the date of the last use
                of Services occurred.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We shall inform You via Your registered Phone Number / E-mail ID
                30 (thirty) days prior to expiry of Your DriveU Wallet. This
                intimation would be sent in the language preferred by You
                indicated at the time registration
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Please note that on the date of Expiry, Your Account will be
                terminated and any remaining balance in Your DriveU Wallet will
                be forfeited.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.23 Disclaimers</span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Except for Our limited role in processing payments that You
                authorize or initiate, We are not involved in any underlying
                transaction between You, any other Customer, Merchant, bank or
                other third party. We are not a selling agent in relation to any
                sale of goods or services to You by any Merchant any other third
                party. We will always endeavor to provide the Services to the
                best of our ability. You are however aware that the Services
                will involve transmissions over various networks and that it
                will change to conform and adapt to the technical requirements
                of connecting networks and devices. There are also various other
                factors, which do not lie within our control.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We shall not be liable for the quality, safety, reliability,
                legality, delivery or any other aspect of any goods or service
                that You may purchase using DriveU Wallet. Your use of the
                Service is at your sole risk. The Service is provided on an "as
                is" and "as available" basis. We disclaim all warranties of any
                kind whether express or implied including without limitation,
                any representation or warranty for accuracy, availability,
                continuity, uninterrupted access, timeliness, sequence, quality,
                performance, security, merchantability, fitness for any
                particular purpose, non-infringement or completeness. Without
                prejudice to the forgoing paragraph, We do not warrant that:
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                (a) This Site will be constantly available, or available at all;
                or
              </span>
            </span>
          </span>
          <br />
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                (b) The information on this Site is complete, true, accurate or
                non-misleading.
              </span>
            </span>
          </span>
          <br />
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                (c) This Site; information, Content, materials, product
                (including software) or services included on or otherwise made
                available to You through the Site; their servers; or electronic
                communication sent from Us are free of viruses or other harmful
                components;
              </span>
            </span>
          </span>
          <br />
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                (d) Nothing on the Site constitutes, or is meant to constitute,
                advice of any kind;
              </span>
            </span>
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We will take every reasonable effort not to share any of the
                Payment Details, Registration Data or other personal information
                (collectively, "Personal Information") You provide Us, except as
                required under RBI Regulations and Applicable Laws, or to the
                limited extent necessary for Us to implement any payment
                instructions We receive from You. Please read Our Privacy Policy
                for further details regarding the use of Your Personal
                Information. We will bear no liability for the consequences in
                any event where Your Personal Information has been willingly or
                inadvertently shared by You with any third party.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We make no express or implied representations or warranties
                about Our Services or the Site and disclaim any implied
                warranties, including, but not limited to, warranties or implied
                warranties of merchantability or fitness for a particular
                purpose or use or non-infringement. We do not authorize anyone
                to make a warranty on Our behalf and You may not rely on any
                statement of warranty as a warranty by Us.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                DriveU and its representatives, officers, employees, agents and
                contractors shall not be liable for any loss, damage, claim,
                expense, cost (including legal costs) or liability arising
                directly or indirectly from Your use or non-use of the Service
                or the Site, or Your reliance upon the Service or the
                information contained upon the Site (whether arising from DriveU
                or any other person's negligence or otherwise).
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You furthermore acknowledge and accept that we may not encrypt
                any Content or communications from and to our Site.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Content and advertisements provided as part of a Service: You
                understand that advertising plays an important role in the
                provision of this Service, and that we will display
                advertisements and other information adjacent to or as part of
                the Services, which you may use. We may periodically send
                promotional emails, SMSs or push notifications to you about
                services offered by our advertisers and ourselves.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Where we publish or provide Content or advertisements as part of
                a Service we do not: warrant or represent that the Content or
                advertisements are suitable, accurate, correct, complete,
                reliable, appropriate, or lawful; or endorse the Content or
                advertisements; and you will obtain independent professional
                advice at your own cost before you take any action based upon
                such Content or advertisements.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Your correspondence or dealings with, or participation in
                promotions of advertisers through the Service, and any other
                terms, conditions, warranties or representations associated
                arising there from, lie between you and the advertisers. You
                agree that we shall not be liable for any loss or damage of any
                nature incurred as the result of any such interactions, or as
                the result of the presence of such advertisers on our Service.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                In many cases, we provide services of third parties, or our
                Services in conjunction with that of third parties. In those
                cases, the following conditions apply: We provide such services
                subject to the terms, conditions and limitations imposed by
                those third parties. If those third parties change, suspend or
                stop providing such services, we may similarly change, suspend
                or stop providing the Services to you without notice. We may
                nevertheless endeavor to provide such a Service in another way
                or by using another third party. You authorise us to provide any
                of your Personal Information (as defined in our Privacy Policy)
                to those third parties to the extent that it may be necessary to
                enable the third parties and ourselves to provide the services
                to you. To the extent that there is a conflict between the third
                party's Terms and Conditions and these Terms, these Terms will
                prevail.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                When you acquire goods, software or any other services from a
                third party through any of our Services, you understand and
                agree that: we are not a party to the contract between you and
                the third party; we are under no obligation to monitor the Third
                Party Service used by you; the third party will be responsible
                for all obligations under the contract including (without
                limitation) warranties or guarantees; you will evaluate the
                product, software or service and the applicable terms and
                conditions before acquiring the product, software or service;
                and you indemnify us against any damages, howsoever arising from
                your acquisition and use of the goods, software or service.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We also reserve the right to reject or refuse any Third Party
                Service used by you in conjunction with our Service.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.24 Notices and communication</span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any notice or notification in relation to these T&amp;Cs which
                You wish to make to Us must be made in writing to: Attn: Humble
                Mobile Solutions Pvt. Ltd. Address: 112/2 17th Main, KHB Colony,
                Koramangala 5th Block, Bengaluru - 560095. E-mail:
                care@DriveU.in
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                All Your communication with Us will be of a professional nature
                only. You will not contact Us to harass, intimidate or threaten
                any person, or to promote any cause, which You may support.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You will be subject to Our T&amp;Cs and privacy policy
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any notice given by DriveU hereunder will be deemed to have been
                received by you within 7 days of posting your address last
                notified in writing to DriveU or, within 24 hours of sending the
                same via e-mail or SMS at the e-mail address specified by you or
                your registered mobile phone number. Any notice may also be sent
                by fax or communicated verbally and confirmed in writing by post
                or fax. DriveU shall not be held accountable for delays in
                receipt of notices by post. In the event of any change in your
                e-mail and/or employment and/or office and/or residential
                address and/or telephone numbers, you shall inform DriveU
                promptly in writing or by e-mail and must confirm the same by
                mail or fax.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              2.25 Indemnity and Limitation of Liability.
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You will defend, indemnify and hold harmless DriveU and each of
                their affiliates (and their respective employees, directors,
                agents and representatives) from and against any and all claims,
                costs, losses, damages, judgments, penalties, interest and
                expenses (including reasonable attorneys' fees) arising out of
                any Claim that arises out of or relates to: to the use or misuse
                of Services or any violation of these T&amp;Cs. For purposes
                hereof: "Claim" means any claim, action, audit, investigation,
                inquiry or other proceeding instituted by a person or entity.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTES WITH US IS THE
                CANCELLATION OF YOUR ACCOUNT WITH THE SITE. IN NO EVENT SHALL
                Drive BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR
                CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THIS
                AGREEMENT, EVEN IF WE HAVE BEEN INFORMED IN ADVANCE OF THE
                POSSIBILITY OF SUCH DAMAGES. IN NO EVENT OUR TOTAL CUMULATIVE
                LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OUR ARISING
                OUT OF YOUR USE OF THE SITE, REGARDLESS OF THE FORM OF ACTION,
                EXCEED ₹ 10,000/- (RUPESS TEN THOUSAND ONLY).
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Without in any way limiting the above, DriveU shall not be
                liable or responsible to you for any loss, damage, expense,
                claim or liability arising directly or indirectly out of any
                defect or deficiency in any goods and services purchased by you
                or availed as a benefit, privilege or facility attached to the
                service; refusal by or inability of a Merchant Establishment to
                honour or accept the service; handing ov er of the relevant
                access control/password/PIN by you to any unauthorised person;
                any injury to the credit, character and reputation of yours
                alleged to have been caused by the repossession of the service
                and/or request for its return.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                All the services to be availed of and /or the goods to be
                purchased /procured by the Customer from the third parties under
                the agreement herein, will be so purchased/procured by the
                Customer at his own risk and costs. The Service Provider shall
                not be liable and/or responsible in respect of the title,
                quantity, quality or any other aspect thereof and no guarantee,
                warranty or any indemnity shall be given or deemed to be given
                by the Service Provider in respect thereof.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.26 Force Majeure</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We shall not be liable for any failure to perform any obligations
              under this T&amp;Cs, if the performance is prevented, hindered or
              delayed by a Force Majeure Event and in such case its obligations
              shall be suspended for so long as the Force Majeure Event
              continues. For the Purpose of this clause “Force Majeure Event”
              shall mean any event arising due to any cause beyond the
              reasonable control of the Party, including, without limitation,
              unavailability of any communication system, sabotage, fire, flood,
              explosion, acts of God, civil commotion, strikes or industrial
              action of any kind, riots, insurrection, war or acts of
              government.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>Copyright complaint</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              We respect the intellectual property of others. In case You feel
              that your work has been copied in a way that constitutes copyright
              infringement, You can write to Us at care@DriveU.in
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.27 Waiver</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Our failure, delay or omission to exercise or enforce any rights
              or provisions of these T&amp;Cs will not constitute a waiver of
              such rights or provisions. A waiver on any one occasion will not
              be construed as a bar or waiver of any rights or remedies on
              future occasions. Any claim in relation to Services or T&amp;Cs
              should be filed within 3 (Three) months from when the cause of
              action arose. Any claims filed beyond this time period shall be
              barred.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.28 Revision of T&amp;Cs.</span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We reserve the right to change any of the T&amp;Cs or any
                policies or guidelines governing the Site or Services, at any
                time and at Our sole discretion. Any changes will be effective
                upon posting of the revisions on the Site and We may send You an
                automated e-mail to Your registered e-mail ID or SMS informing
                about the changes made and You would be required to view the
                modified changes on the internet. By keeping or using the
                service after notification, You are deemed to have agreed to the
                amendment/change. If You do not agree with any amendment of the
                terms and conditions You may cancel the service by informing in
                writing to DriveU or closing your account on the internet or by
                SMS.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Unless otherwise specified by Us, revised T&amp;Cs will take
                effect automatically and be binding from the day they are posted
                on the Site. By continuing to access or use Services, You will
                be deemed to have agreed to accept and be bound by such revised
                T&amp;Cs. If You do not agree to the revised T&amp;Cs, You
                should discontinue accessing Our Site or using Our Services
                immediately.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                We reserve the right, at Our sole discretion, to change, modify,
                add or remove portions of these T&amp;Cs, at any time without
                any prior written notice to You. It is Your responsibility to
                review these T&amp;Cs periodically for updates / changes. Your
                continued use of the Website following the posting of changes
                will mean that You accept and agree to the revisions. As long as
                You comply with these T&amp;Cs, We grant You a personal,
                non-exclusive, non-transferable, limited privilege to enter and
                use the Site.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              2.29 Customer grievance redressal policy
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                If You are not satisfied with the quality of Service(s) offered
                or have any other complaints or grievance, please contact Our
                customer care at care@DriveU.in
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                The procedure of escalation of complaints shall be as follows:
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Escalation Level – 1: Write to care@DriveU.in
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Response Time: Seven Business Days
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Escalation Level – 2: Write to Grievance Officer
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              To, Attn: Grievance Officer, Humble Mobile Solutions Pvt. Ltd.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Address: 112/2 17th Main, KHB Colony, Koramangala 5th Block,
              Bengaluru - 560095.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>E-mail: care@DriveU.in</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Response Time: Fifteen Business Days
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.30 Severability.</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              If any part of these T&amp;Cs is determined to be invalid or
              unenforceable pursuant to the Applicable Laws then the invalid or
              unenforceable provisions will be deemed superseded by a valid,
              enforceable provision that most closely matches the intent of the
              original provision and the remainder of these T&amp;Cs will
              continue in effect.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              2.31 Governing law and dispute resolution.
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any dispute, controversy or claim arising out of or relating to
                this T&amp;Cs or the validity, interpretation, breach or
                termination thereof (“Dispute”), including claims seeking
                redress or asserting rights under the Applicable Law shall be
                amicably settled through mutual consultation and escalation. If
                the Dispute is not settled amicably as aforesaid within a period
                of [14] (Fourteen) calendar days, the matter would be referred
                to arbitration in accordance with the provisions of the
                Arbitration and Conciliation Act, 1996. Arbitration shall be
                presided over by a sole arbitrator mutually appointed by Us and
                the Customer.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                The arbitration proceedings shall be conducted in the English
                language. The venue for the arbitration proceedings shall be
                Mumbai.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                This T&amp;Cs shall be governed by and construed in accordance
                with the laws of India and, subject to this Clause 30, We and
                You agree and undertake that any controversy or claim arising
                out of or relating to these T&amp;Cs will be adjudicated
                exclusively before a competent court in Mumbai, India only.
              </span>
            </span>
          </span>
        </li>
      </ul>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2.32 Miscellaneous</span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any costs incurred by DriveU towards enforcement of its rights
                and recovery shall be debited to your account.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Where DriveU acts on good faith in response to any oral or
                electronic instruction or inquiry from you in respect of any
                matter in relation to your account, you will not be entitled to
                claim or allege any loss, damage, liability, expense etc.,
                attributable, directly or indirectly, to any such good faith
                action of DriveU and you agree to hold DriveU harmless in
                respect thereof.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                If required by law to do so or if considered necessary to do so
                for proper management of credit or business risk, DriveU
                reserves the right to disclose any and all Cardholder
                information, including default in any payments or misuse of the
                service, to any court of competent jurisdiction, quasi judicial
                authority, law enforcement agency, relevant wing of the Central
                Government or State Government, your employer, credit reference
                agency, financial institution or any company which is an
                affiliate or associate or parent or subsidiary of DriveU 31.4
                You hereby agree that the application and copies of all
                documents submitted in support thereof and for the ongoing
                operation of the DriveU Account by you to DriveU are and shall
                remain the sole and exclusive property of DriveU, and DriveU
                shall not be obliged to return the same to you upon your request
                or upon termination of this Agreement.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You agree to comply with all applicable laws and regulations
                from time to time which govern or may be affected by the use of
                the service.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                DriveU, our parent, subsidiaries, employees and our suppliers
                provide our services "as is" and without any warranty or
                condition, express, implied or statutory. DriveU, our parent,
                subsidiaries, employees and our suppliers specifically disclaim
                any implied warranties of title, merchantability, satisfactory
                quality, fitness for a particular purpose and non-infringement.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                In no event shall DriveU, our parent, subsidiaries, employees or
                our suppliers be liable for lost profits or any special,
                incidental or consequential damages arising out of or in
                connection with our web site, our service, or the T&amp;C
                (however arising, including negligence). DriveU shall make
                reasonable efforts to ensure that requests for electronic debits
                and credits involving bank accounts, credit cards, and check
                issuances are processed in a timely manner and but DriveU makes
                no representations or warranties regarding the amount of time
                needed to complete processing because our Service is largely
                dependent upon many factors outside of our control.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                You are aware of all security risks, including possible third
                party interception of any of your transactions and statements of
                account on the Internet and the contents of your transactions or
                statements of account becoming known to third parties. You agree
                and undertake that you shall not hold DriveU liable therefore in
                any way. The use and storage of any information, including
                without limitation, the Card number, PIN, password, account
                information, transaction activity, account balances and any
                other information available on your Internet access device or
                computer or mobile phone is at your own risk and responsibility.
                31.9 As your account ID is connected to your mobile number, it
                is your responsibility to inform DriveU in case you terminate
                using the mobile number that corresponds to your account ID. The
                account ID corresponding to the mobile number you register with
                can be accessed by you only till the time you have access to the
                corresponding mobile number. It is your responsibility to
                transfer your account ID to a new mobile number if and when you
                change your number. Not doing so may give access to your DriveU
                Wallet credit balance to the new user of the same mobile number,
                and DriveU will not be liable or responsible for the same. You
                are also liable for ensuring the security of your PIN. In case
                of loss of your mobile phone and/or DriveU Password/PIN, you
                should intimate DriveU in writing immediately. DriveU shall make
                every attempt to block access to your DriveU account under such
                circumstances, however, DriveU shall not be liable for any
                losses a user may incur due to the above. The responsibility to
                safeguard the DriveU coupon balance rests entirely with the
                user.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                By signing up for DriveU services, you also allow DriveU to send
                you emails and SMS alerts from time to time.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                DriveU will be entitled to enter into any tie-up in terms of
                joint-venture or otherwise with any other institution engaged in
                the business of providing services analogous
              </span>
            </span>
          </span>
        </li>
      </ul>
      <br />
      <p>
        <span style={{ fontSize: "11pt" }} className="ConditionText">
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              <strong>DU Secure Vehicle Damage Protection Plan:</strong>
              <br />
            </span>
          </span>
        </span>
        {/* <br /> */}
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              DU Secure Vehicle Damage Protection Plan will be applicable only
              to the trips booked through DriveU platform. The cover under this
              plan starts when the driver boards the DriveU’s Customer car and
              lasts till the customer alights from the car and ends the DriveU
              trip. The maximum period of coverage is 12hrs for local trips,
              24hrs for one-way outstation trips and 36hrs for outstation trips.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Assets Covered: Car of Customer of DriveU driven by the DriveU
              Driver Partner for the particular trip.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Coverage: Cover upto INR 15000 or actual repair bill whichever is
              lower if any damages occur during the service delivery
            </span>
          </span>
        </span>
      </p>
      <br />
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              <strong>General Conditions:</strong>
            </span>
          </span>
        </span>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>&nbsp;</span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Written notice within 3 days of any event should be given that
                may give rise to a claim under the Plan, in accordance with the
                claims procedure under the Plan. Any claims initiated after 3
                days of the event shall be rejected by DriveU.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Repair invoice needs to be uploaded within 15 days from the date
                of Invoice Upload request by DriveU. If the repair invoice is
                uploaded after 15 days of the upload request, such claims shall
                be rejected by DriveU.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                All claims made under the plan will be subject to the applicable
                deductible, any sub-limits and the availability of the sum
                coverage.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                The plan does not cover any contractual and consequential
                liability, except as covered in the Plan or coverage mentioned
                by DriveU to its customer.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Geographical Limit: India.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Plan is non-transferable.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <br />
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              <strong>Special Conditions:</strong>
            </span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                The cover under this plan starts when the driver boards the
                DriveU’s Customer car and lasts till the customer alights from
                the car and ends the DriveU trip. The maximum period of coverage
                is 12hrs for local trips, 24hrs for one-way outstation trips and
                36hrs for outstation trips.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Only visible, external and accidental damages to the vehicle are
                covered under the plan.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Damages occurred during the trip will only be eligible for the
                claim.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Repairs from GST authorized service centers will only be
                eligible for the claim.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any loss occurring due to normal wear and tear of the vehicle
                will not be eligible for a claim.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any form of mechanical or electrical breakdown, failure of
                chassis, or body parts, or breakage due to rusting or other
                weather conditions will not be considered for a claim.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Replacement of parts as a result of normal wear and tear, such
                as spark plugs, belts, brake pads and brake shoes, brake discs,
                clutch disc/pressure plate, filters, wiper blades, bulbs, fuses,
                brushes, hoses, Tire / tube, battery, audio system, audio video
                navigation (AVN), catalytic converter, silencer / exhaust pipe
                will not be covered.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Damage to Tyres, Electrical equipment, or Electronics will not
                be covered.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Rubber parts like Wheel Cylinder kit, all doors
                weather-strips/beadings &amp; A/C "O" rings, Clutch cable,
                Accelerator cable, Speedometer cable &amp; Tail Door Opener
                cable, leakage of A/C refrigerant will not be covered.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Normal aging, deterioration or rusting of plated parts, paint
                coat, rubber parts, upholstery and soft trim, etc will not be
                covered.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Interior / exterior trim, accessories, exhaust systems and
                normal wear and tear of any other component will not be covered.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Vehicles without valid motor vehicle insurance will not be
                covered.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <br />
      <p>
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              <strong>Exclusions:</strong>
            </span>
          </span>
        </span>
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Fraud or criminal activity on the part of the customer or the
                Beneficiary
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss resulting from detention or confiscation by customs or
                other Government or public authorities
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any failure of the vehicle to operate as a result of problems
                with respect to mechanical and electrical failures
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss arising due to any unlawful or illegal activities including
                criminal acts or acts of war or the insured equipment is used
                with permission of the bonafide beneficiary having knowledge of
                such facts
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Consequential loss of any kind or description
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Liability to any party of any nature including but not limited
                to liability of Authorized Service Centre to other parties.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss or damage as a result of manufacturing defect owing to
                which a insured equipment fails to operate
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss or damage that is covered by a supplier, dealer or factory
                warranty
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Any loss if the ownership of the insured equipment is
                transferred Any consequence arising from War, War like
                operations ( whether war declared or not ), Act of foreign
                enemy, hostilities, Civil War
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Rebellion, Insurrection, Civil Commotion, Military unsurped
                power, Seizure, Capture ,Confiscation, Arrest, Restraints and or
                detainment by order of any Government or any other Authority
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Intentional overloading of the insured equipment. Loss or damage
                due to any experiments or tests and/or alterations resulting
                into any abnormal conditions
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss or damage to accessories of whatsoever nature
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss or damage caused by any third party. Such damages need to
                be claimed through the vehicle insurance of the customer.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Commercial or yellow board vehicles are not eligible for the
                insurance.
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Loss or damage caused to any third party. Such damages need to be claimed through the vehicle insurance of the customer.
              </span>
            </span>
          </span>
        </li>
      </ol>
      <p style={{ listStyleType: "decimal" }}>&nbsp;</p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#000000" }}>
              Please note that this is only a basic description of the key terms
              of the Policy.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
    </div>
  );
}

export default TermConditionNew;
